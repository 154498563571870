import React from 'react';
import Board from '../components/board/Board';
import Layout from '../components/layout';

export default function board() {
  return (
      <Layout>
        <Board />
      </Layout>
  );
}
