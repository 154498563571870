import { Link } from 'gatsby';
import React, { PureComponent } from 'react';
import styles from '../styles/Header.module.css';
import beach1 from '../../images/background-images/beach1.jpg';
import beach2 from '../../images/background-images/beach2.jpg';
import beach3 from '../../images/background-images/beach3.jpg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export default class Nav extends PureComponent {
  state = {};
  handleScroll = this.handleScroll.bind(this);

  handleScroll() {
    this.setState({ scrolledTo: window.scrollY });
  }

  async componentDidMount() {
    const nav = document.querySelector('nav');
    const woodsPondTitle = document.getElementById(styles.woodsPondTitle);
    const wpwqaTitle = document.getElementById(styles.wpwqaTitle);
    const carousel = document.getElementById('carousel');

    const currentLink = document.getElementById(
      window.location.pathname.slice(1)
    );
    if (currentLink) {
      currentLink.classList.add(styles.currentLink);
    }

    if (
      window.location.pathname !== '/' &&
      window.location.pathname !== '/about'
    ) {
      woodsPondTitle.style = 'display: none';
      wpwqaTitle.style = 'display:none';
      carousel.style = 'margin-top: -1000px';
      nav.classList.add(styles.fixedNav);
    }

    this.setState(
      {
        top: nav.offsetTop,
        height: nav.offsetHeight,
        onLandingPage: true,
        scrolledTo: 0,
      },
      () => (document.body.style.paddingTop = `${this.state.height}px`)
    );
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scrolledTo > this.state.top && window.innerWidth > 699
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = 0);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const links = [
      { title: 'ABOUT', ref: 'about' },
      { title: 'BOARD', ref: 'board' },
      { title: 'RESOURCES', ref: 'resources' },
      { title: 'GALLERY', ref: 'gallery' },
      { title: 'CONTACT', ref: 'contact' },
      { title: 'DONATE', ref: 'donate' },
    ];
    const listLinks = links.map((link, i) => {
      return (
        <Link key={i} to={link.ref} id={link.ref} className={styles.navLink}>
          {link.title}
        </Link>
      );
    });

    return (
      <div>
        <div id={styles.landing}>
          <div className={styles.landingImg}>
            <div id="carousel">
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={false}
                showArrows={false}
                showIndicators={false}
                showStatus={false}
              >
                <div>
                  <img
                    alt="beach"
                    src={beach1}
                    height="1000px"
                    id="landingImage"
                  />
                </div>
                <div>
                  <img
                    alt="beach"
                    src={beach2}
                    height="1000px"
                    id="landingImage"
                  />
                </div>
                <div>
                  <img
                    alt="beach"
                    src={beach3}
                    height="1000px"
                    id="landingImage"
                  />
                </div>
              </Carousel>
            </div>

            <div id={styles.hamburgerContainer}>
              <label id={styles.hamburgerLabel}>
                Checkbox for hamburger button
              </label>
              <input type="checkbox" id={styles.hamburgerInput} />
              <span className={styles.hamburgerSpan} />

              <div id={styles.mobileNav}>{listLinks}</div>
            </div>
          </div>
          <h1 id={styles.woodsPondTitle}>WOODS POND</h1>
          <h1 id={styles.wpwqaTitle}>Woods Pond Water Quality Association</h1>
          <div id={styles.desktopNav}>
            <nav
              id={styles.navBar}
              className={
                this.state.scrolledTo > this.state.top ? styles.fixedNav : ''
              }
            >
              {listLinks}
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
