import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import globalStyles from '../styles/Main.module.css';

const Board = () => (
  <div className={globalStyles.section}>
    <h1 className={globalStyles.sectionTitle}>WPWQA Board Members</h1>
    <StaticQuery
      query={graphql`
        query {
          allContentfulBoard {
            edges {
              node {
                name
              }
            }
          }
        }
      `}
      render={data => {
        return data.allContentfulBoard.edges[0].node.name.map(member => {
          return (
            <p
              key={member}
              className={globalStyles.standardText}
              style={{ 'textAlign': 'center' }}
            >
              {member}
            </p>
          );
        });
      }}
    />
  </div>
);

export default Board;
